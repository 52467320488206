module.exports = {
  _pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  _title: 'ISKO', // Navigation and Site Title
  _titleAlt: 'ISKO', // Title for JSONLD
  description:
    'ISKO Denim is one of world\'s leading denim producers with a production capacity of 350 million meters per year',
  _url: 'https://iskodenim.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/logo-1024.png', // Used for SEO

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'ISKO', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Like.Digital', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@ISKODENIM' // Twitter Username
};
